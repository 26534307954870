import './firstSection.scss'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Typed from 'typed.js';

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';

import project1 from "../../../assets/projects/project-1.png";
import project2 from "../../../assets/projects/project-2.png";
import project3 from "../../../assets/projects/project-3.png";

import { motion } from 'framer-motion';
import { fadeIn } from '../../../utils/framer';







export const FirstSection = () => {




  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_qv3f66e', 'template_bdkowql', form.current, {
        publicKey: '8Nwqg799RN2CHaHSR',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };



  return (
    <>


    {/* first-sec */}

      <div className="overflow-hidden flex lg:flex-row flex-col-reverse p-10 py-20 lg:gap-0 gap-10 lg:h-full">

        <div className=" flex flex-col items-start justify-center lg:w-[50vw]">

          <motion.p
            variants={fadeIn('right', 0.1, 0.4)}
            initial='hidden'
            whileInView={'show'}

            className='text-white opacity-40 text-xl'>&lt;html&gt;</motion.p>
          <div className="ms-5">
            <motion.p
              variants={fadeIn('right', 0.2, 0.4)}
              initial='hidden'
              whileInView={'show'}

              className='mb-5 text-white opacity-30 text-xl'>&lt;body&gt;</motion.p>
            <motion.p
              variants={fadeIn('right', 0.3, 0.4)}
              initial='hidden'
              whileInView={'show'}

              className='mb-5 text-white opacity-30 text-xl'>&lt;h1&gt;</motion.p>
            <div className="lg:text-6xl text-3xl text-white font-bold">
              <h1>Hello</h1>
              <h1>Im <span className='bg-gradient-to-r from-green-500 to-green-400  bg-clip-text text-transparent'>Badreddine Faras<span className='text-white'>,</span></span></h1>
              <h1 className='mb-5 '>Web developer</h1>
            </div>
            <p className='text-white opacity-30 text-xl'>&lt;/h1&gt;</p>
            <p className='text-white opacity-30 text-xl'>&lt;p&gt;</p>
            <h3 className='text-2xl font-semibold text-white opacity-80'>Full Stack developer</h3>
            <p className='text-white opacity-30 text-xl'>&lt;/p&gt;</p>
          </div>





        </div>


        {/* image */}
        <motion.div
          variants={fadeIn('left', 0.1)}
          initial='hidden'
          whileInView={'show'}

          className="">
          <div className="binary  lg:h-[70vh]">
            <motion.div
              variants={fadeIn('left', 0.2)}
              initial='hidden'
              whileInView={'show'}
              className="bg  lg:w-[50vw] lg:h-full sm:h-[50vh]  w-full h-[40vh] bg-no-repeat bg-center bg-contain backdrop-filter"></motion.div>
          </div>
        </motion.div>


      </div>





    </>
  );
}
