import './firstSection.scss'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Typed from 'typed.js';

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';

import project1 from "../../../assets/projects/project-1.png";
import project2 from "../../../assets/projects/project-2.png";
import project3 from "../../../assets/projects/project-3.png";

import { motion } from 'framer-motion';
import { fadeIn } from '../../../utils/framer';





export const Skills = () => {




  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_qv3f66e', 'template_bdkowql', form.current, {
        publicKey: '8Nwqg799RN2CHaHSR',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  

  return (
    <>

      

      {/* cmd */}
      <div className="p-5">
        <div className="text-green-500 cmd-font text-3xl bg-black w-72 p-3 rounded-md bg-opacity-70 m-auto">
          <span className='text-blue-600'>~</span><span className='text-white'>$ cd Skills</span>
        </div>
      </div>

      {/* skills */}
      <div name="skills" className="text-xl text-green-500  cmd-font  p-10">

        <div className="bg-black w-full rounded-lg">
          <div className="flex p-2 gap-1">
            <div className="">
              <span className="bg-red-500 inline-block center w-3 h-3 rounded-full"></span>
            </div>
            <div className="circle">
              <span className="bg-yellow-500 inline-block center w-3 h-3 rounded-full"></span>
            </div>
            <div className="circle">
              <span className="bg-green-500 box inline-block center w-3 h-3 rounded-full"></span>
            </div>
          </div>

          <div className="card__content p-5">
            <p>B@F<span className='text-white'>:</span><span className='text-blue-600'>~/Skills/Languages</span><span className='text-white'>$ ls</span><span className='ms-3 text-white '>
              <div className="flex flex-row flex-wrap items-center gap-3 ms-5 text-2xl">
                <p className='text-orange-500'>Html</p>
                <p className='text-sky-500'>Css</p>
                <p className='text-fuchsia-500'>Sass</p>
                <p className='text-yellow-500'>Javascript</p>
                <p className='text-purple-600'>Bootstrap</p>
                <p className='text-cyan-500'>Tailwind</p>
                <p className='text-cyan-500'>React</p>
                <p className='text-orange-400'>MySql</p>
                <p className='text-red-600'>Laravel</p>
                <p className='text-blue-500'>C</p>

              </div>
            </span></p>

          </div>


        </div>
      </div>

      {/* Tools */}

      <div className="text-xl text-green-500  cmd-font  px-10 pb-10">

        <div className="bg-black w-full rounded-lg">
          <div className="flex p-2 gap-1">
            <div className="">
              <span className="bg-red-500 inline-block center w-3 h-3 rounded-full"></span>
            </div>
            <div className="circle">
              <span className="bg-yellow-500 inline-block center w-3 h-3 rounded-full"></span>
            </div>
            <div className="circle">
              <span className="bg-green-500 box inline-block center w-3 h-3 rounded-full"></span>
            </div>
          </div>

          <div className="card__content p-5">
            <p>B@F<span className='text-white'>:</span><span className='text-blue-600'>~/Skills/Tools</span><span className='text-white'>$ ls</span><span className='ms-3 text-white '>
              <div className="flex flex-row flex-wrap items-center gap-3 ms-5 text-2xl">
                <p className='text-sky-500'>Vscode</p>
                <p className='text-white'>Bash</p>
                <p className='text-orange-500'>Git</p>
                <p className='text-red-600'>Npm</p>

              </div>
            </span></p>

          </div>


        </div>
      </div>



    </>
  );
}
