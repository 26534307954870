import './firstSection.scss'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Typed from 'typed.js';

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';

import project1 from "../../../assets/projects/project-1.png";
import project2 from "../../../assets/projects/project-2.png";
import project3 from "../../../assets/projects/project-3.png";

import { motion } from 'framer-motion';
import { fadeIn } from '../../../utils/framer';





export const About = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_qv3f66e', 'template_bdkowql', form.current, {
        publicKey: '8Nwqg799RN2CHaHSR',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };



  return (
    <>



      {/* cmd */}
      <div className="p-5">
        <div className="text-green-500 cmd-font text-3xl bg-black w-72 p-3 rounded-md bg-opacity-70 m-auto">
          <span className='text-blue-600'>~</span><span className='text-white'>$ cd About</span>
        </div>
      </div>

      {/* about us */}
      <div name="about" className="text-xl text-green-500  cmd-font  p-10">

        <div className="bg-black w-full rounded-lg">
          <div className="flex p-2 gap-1">
            <div className="">
              <span className="bg-red-500 inline-block center w-3 h-3 rounded-full"></span>
            </div>
            <div className="circle">
              <span className="bg-yellow-500 inline-block center w-3 h-3 rounded-full"></span>
            </div>
            <div className="circle">
              <span className="bg-green-500 box inline-block center w-3 h-3 rounded-full"></span>
            </div>
          </div>
          <div className="card__content p-5">
            <p>B@F<span className='text-white'>:</span><span className='text-blue-600'>~/About</span><span className='text-white'>$ vim about.txt</span></p>

          </div>
          <div className='bg-blue-900 p-4 bg-opacity-50 rounded-b-lg'>

            <p className='text-white ms-3 '><span className='bg-blue-600'>A</span> passionate web developer based in Casablanca, I thrive on translating ideas into captivating online
              experiences. Proficient in HTML, CSS, and JavaScript, my journey in web development is fueled by a genuine
              love for creating seamless and visually engaging websites. Living in the vibrant city of Casablanca adds
              an extra layer of inspiration to my work, as I draw from its unique blend of tradition and modernity.
              Let's embark on a digital journey together and bring your visions to life! <br />
              ~ <br />
              ~ <br />
              ~ <br />
              ~ <br />
              ~ <br />
              ~ <br />
              ~ <br />
              ~ <br />
              ~ <br />
              ~ <br />
              ~ <br />
              ~ <br />
              "about.txt"  6L,  554B
            </p>

          </div>
        </div>
      </div>




    </>
  );
}
